<template>
  <Wrapper>
    <div class="business">

      <!-- banner -->
      <DetailBanner title="关于公司业务"></DetailBanner>
      <!-- 业务板块 -->
      <div class="sector">
        <div class="w">
          <water-title Chinese="业务板块" English="BUSINESS SECTOR"></water-title>
          <ul>
            <li v-for="(item, index) in busList" :key="item.id">
              <div class="image">
                <img :src="item.corporateUrl" />
              </div>
              <div class="text">
                <h3>{{index + 1}}/{{item.title}}</h3>
                <div class="small">{{item.corporateName}}</div>
                <p>{{item.description}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </Wrapper>
</template>

<script>
import DetailBanner from './sections/DetailBanner.vue'
import WaterTitle from '../components/WaterTitle.vue'
import Wrapper from '../components/Wrapper.vue'

export default {
  mixins: [],
  components: { Wrapper, DetailBanner, WaterTitle },
  data() {
    return {
      busList: []
    }
  },
  created() {
    this.scrollTop()
    this.getMsg()
  },
  methods: {
    scrollTop() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        console.log(scrollTop)
      if (scrollTop === 0) {
        return false
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    async getMsg() {
      const res = await this.$axios.getCorporateBusinessPage({
        offset: 1,
        limit: 7,
        statusCd: 0
      })
      this.busList = res.records
    }
  }
}
</script>

<style lang='scss' scoped>
.sector {
  padding: 100px 0;
  ul {
    margin-top: 50px;
  }
  li {
    display: flex;
    background-color: rgb(246, 250, 253);
    margin-bottom: 20px;
    &:nth-child(2n) {
      .text {
        order: 1;
      }
      .image {
        order: 2;
      }
    }
    .text {
      padding: 30px 40px;
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      color: #333;
      margin-bottom: 10px;
    }
    .small {
      color: #aaa;
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 16px;
        height: 4px;
        background-color: #0054e4;
      }
    }
    p {
      line-height: 1.5;
      color: #666;
    }
  }
}
</style>
